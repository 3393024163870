<script lang="ts" setup>
import version from '../../../package.json'

const route = useRoute()
const router = useRouter()

const auth = useAuth()
const keycloak = useKeycloak()
const mbtv = useMBTV()

const userSelectModelIsOpen = ref(false)

const navigation = computed(() => {
  if (auth.session.status !== 'unauthenticated') {
    const menuPoints = []

    if (!auth.session.isAdmin) {
      menuPoints.unshift(
        {
          name: 'Leseplan-Kategorien',
          href: '/kategorien',
          current: route.path.startsWith('/kategorien'),
        },
        {
          name: 'Leseplan-Reviews',
          href: '/reviews',
          current: route.path.startsWith('/reviews'),
        },
      )
    }

    if (auth.session.isModerator) {
      menuPoints.unshift({
        name: 'Alle Lesepläne',
        href: '/leseplaene',
        current:
          route.path.startsWith('/leseplaene')
          && route.query.onlyOwn !== 'true'
          && route.query.highlight !== 'true',
      })
      menuPoints.push({
        name: 'Promo Lesepläne',
        href: '/leseplaene?filter&highlight=true&refetch=1',
        current: route.path.startsWith('/leseplaene') && route.query.highlight === 'true',
      })
    }

    if (auth.session.isModerator || auth.session.isCreator) {
      menuPoints.unshift({
        name: 'Meine Lesepläne',
        href: '/leseplaene?filter&onlyOwn=true',
        current: route.path.startsWith('/leseplaene') && route.query.onlyOwn === 'true',
      })
    }

    if (!auth.session.isCreator) {
      menuPoints.push({
        name: 'Nutzer',
        href: '/users',
        current: route.path.startsWith('/users'),
      })
      menuPoints.push({
        name: 'Stats',
        href: '/stats',
        current: route.path.startsWith('/stats'),
      })
    }

    return menuPoints
  }
  return []
})

async function logout() {
  await keycloak.logout()
  await mbtv.logout()
  router.push({
    path: '/loginSelection'
  })
}
</script>

<template>
  <div class="min-h-full">
    <header>
      <nav class="border-b border-gray-200 bg-white">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between py-2">
            <div class="flex">
              <div class="flex flex-shrink-0 items-center">
                <img class="mt-1 block h-16 w-auto" src="/logo.svg" alt="BibelTV Logo" />
              </div>
              <div class="flex items-center gap-4 sm:-my-px sm:ml-6">
                <NuxtLink
                  v-for="item in navigation"
                  :key="item.name"
                  :href="item.href"
                  class="inline-flex items-center border-b-2 px-1 pt-1 text-base font-medium shrink"
                  :class="[
                    item.current
                      ? 'border-bibeltv-500 text-gray-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  ]"
                  :aria-current="item.current ? 'page' : undefined"
                >
                  {{ item.name }}
                </NuxtLink>
              </div>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:items-center">
              <!-- Profile dropdown -->
              <div class="relative ml-3">
                <div>
                  <div
                    class="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span
                      class="absolute -inset-1.5 cursor-pointer"
                      @click="
                        () => {
                          userSelectModelIsOpen = true
                        }
                      "
                    />
                    <span class="sr-only">Open user menu</span>
                    <UDropdown
                      v-if="auth.session.user"
                      :items="[
                        [
                          {
                            label: 'Logout',
                            click: async () => {
                              await logout()
                            },
                          },
                        ],
                      ]"
                    >
                      <UAvatar
                        :src="auth.session.user.profilePictureUrl ?? ''"
                        :alt="
                          auth.session.user.firstName || auth.session.user.lastName
                            ? `${auth.session.user.firstName} ${auth.session.user.lastName}`
                            : auth.session.user.userName ?? ''
                        "
                      />
                    </UDropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="absolute right-2 top-2 text-neutral-600">{{ version.version }}</div>
        </div>
      </nav>
    </header>

    <div class="py-10">
      <main>
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
